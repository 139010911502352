import * as React from "react";
import { Helmet } from "react-helmet";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../../components/layout";
import Cta from "../../components/cta";
import * as Styles from "../../styles/effect.module.css";

const Guidelines = () => {
  return (
    <Layout pageTitle="Home Page">
      {/* MetaTag */}
      <Helmet
        htmlAttributes={{
          lang: "en",
        }}
      >
        <title>
          Installation Guidelines - KATSANA® Integrated Fleet Management &
          Industrial Operation Solutions
        </title>
        <meta
          name="description"
          content="We are transforming fleet & enterprise operations for the digital age."
        />
        <meta
          name="robots"
          content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
        />
        <link
          rel="canonical"
          href="https://www.katsana.com/support/installation-guidelines/"
        />

        {/* Open Graph */}
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://www.katsana.com/support/installation-guidelines"
        />
        <meta
          property="og:title"
          content="About Us - KATSANA® Integrated Fleet Management & Industrial Operation Solutions"
        />
        <meta
          property="og:description"
          content="We are transforming fleet & enterprise operations for the digital age."
        />
        <meta
          property="og:image"
          content="https://assets.katsana.com/og-images/twt_ogimage_large.jpg"
        />
        <meta
          property="og:image:alt"
          content="KATSANA Fleet Management & Industrial IoT Sensors Expert"
        />
        <meta property="og:image:width" content="1280" />
        <meta property="og:image:height" content="640" />

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@katsanagps" />
        <meta name="twitter:creator" content="@katsanagps" />

        {/* image must be 2:1 max 5mb */}
        <meta
          name="twitter:image"
          content="https://assets.katsana.com/og-images/twt_ogimage_large.jpg"
        />
      </Helmet>
      {/* Hero Area */}
      <div className="relative p-8 pt-12 pb-24 overflow-hidden xl:px-0  md:pt-32 md:pb-48">
        <StaticImage
          alt="katsana"
          src="../../../static/images/photos/aboutus-hero.jpg"
          objectFit="cover"
          className="!absolute inset-0"
        />

        <div className="relative z-10 max-w-6xl mx-auto ">
          <h3 className="pt-24 md:pt-12 text-lg md:text-2xl text-bluegray">
            Straightforward and Easy
          </h3>
          <h1 className="pt-2 text-3xl font-bold tracking-wide text-white md:leading-tight md:mt-0 md:text-6xl">
            Installation Guidelines
          </h1>
        </div>
      </div>

      <div className=" bg-gradient-to-b from-[hsla(204,26%,93%,1)] to-white relative pb-36">
        <div className="absolute top-0 left-0 w-full">
          <div
            className={
              Styles.tilt +
              " after:bg-[hsla(204,26%,93%,1)] z-10 m-[-1px] after:h-[4vh] md:after:h-[10vh] z-10 "
            }
          ></div>
        </div>
        <div className="max-w-4xl p-8 xl:p-16 mx-auto bg-gradient-to-b from-[hsla(204,26%,93%,0)] to-[hsla(204,26%,99%,0)] via-white text-gray-800  relative">
          <h3 className="text-2xl font-medium">
            Katsana GPS Tracker Installation Guidelines
          </h3>
          <p className="mt-6 leading-7">
            Installation of Katsana GPS Tracker is fairly straightforward and
            easy. Simply power it up with a 12V (or 24V) DC constant power, this
            device will switch on automatically and start tracking your vehicle
            straightaway.
            <br />
            <br />
            Other GPS tracking devices sold in Malaysia often require you to do
            relays, wire cutting and other modifications that will likely void
            the warranty of your vehicles. Katsana GPS/GLONASS tracker however
            is perfectly safe due to the reasons below. Plus, you can also hide
            it anywhere you desire within the car.
          </p>

          <ul className="mt-4 ml-6 leading-7 list-disc">
            <li>
              Katsana would only need a constant 12V (DC) power input from your
              vehicle. The ground cable can be tapped onto the vehicle’s chasis.
            </li>
            <li>Katsana trackers do not require you to cut vehicle wires.</li>
            <li>
              Katsana tracking device does not require you to create relays/tap
              into vehicle ECU/fuelling system. Typical GPS trackers in Malaysia
              that do all this will definitely void vehicle warranty.
            </li>
            <li>
              Katsana GPS tracker can be hidden anywhere within the vehicle
              because you only need to power it up with 12VDC. You can hide
              Katsana tracker at the back, within the vehicle C-Pillar section,
              within the door, or any place that you can imagine. Common
              China-made GPS trackers are often installed at the same place,
              which is under the dashboard. This makes it easy for thief to
              locate and get rid of it.
            </li>
            <li>
              Katsana GPS Tracker is weatherproof. Have we told you that our
              tracker is IP67 rated, meaning it can be dropped into 1 meter deep
              of water?
            </li>
          </ul>

          <h3 className="mt-12 text-2xl font-medium">Wiring Diagrams</h3>
          <h4 className="mt-6 text-xl font-medium">Connecting wires</h4>
          <div className="flex flex-col-reverse mt-4 md:flex-row">
            <ul className="mt-4 ml-6 leading-7 list-disc md:w-3/4 md:mt-0 ">
              <li>
                Wires should be fastened to other wires or non-moving parts.
                Avoid heat emitting and moving objects near the wire.
              </li>
              <li>
                The connection should be hidden properly. If factory isolation
                is removed while connecting wires, it should be applied again.
              </li>
              <li>
                If the wires are placed at the exterior or places exposed to
                heat, humidity, dirt, etc., additional isolation should be
                applied.
              </li>
              <li>
                Do not connect the wires to board computers or control units.
              </li>
            </ul>
            <StaticImage
              alt="katsana"
              src="../../../static/images/graphics/wiring-diagram.png"
              objectFit="contain"
              className="mt-6 md:ml-8 md:mt-0"
            />
          </div>

          <h4 className="mt-6 text-xl font-medium">Connecting Power Source</h4>
          <ul className="mt-4 ml-6 leading-7 list-disc">
            <li>
              Be sure that after the car computer falls asleep (usually in
              5-30min period), power is still available on the chosen wire.
            </li>
            <li>
              It is recommended to connect to main power cable in the fusebox.
            </li>
          </ul>
          <h4 className="mt-6 text-xl font-medium">Connecting Ground Wire</h4>
          <ul className="mt-4 ml-6 leading-7 list-disc">
            <li>
              Ground wire is connected to the vehicle frame or metal parts that
              are fixed to the frame.
            </li>
            <li>
              If the wire is fixed with bolt, the loop must be connected to the
              end of the wire.
            </li>
            <li>
              For better contact, scrub paint from the place where loop is
              connected.
            </li>
          </ul>

          <h4 className="mt-6 text-xl font-medium text-red-500">WARNING</h4>
          <ul className="mt-4 ml-6 italic leading-7 list-disc">
            <li>
              Connecting the power supply must be carried out on a low-impedance
              point on-board vehicle network. These points in the car are
              battery terminals.
            </li>
            <li>
              Therefore, we recommend connecting the power of KAT3000 (POWER and
              GROUND wire) directly to the battery terminals.
            </li>
            <li>
              Another valid option is to connect the wires to the main POWER
              cable inside the fusebox. GROUND wire must be connected in a
              special point, designed to connect GROUND wires.
            </li>
            <li>
              Connecting GROUND at an arbitrary point to the car is unaccept-
              able, as static and dynamic potentials on the GROUND line will be
              unpredictable.
            </li>
          </ul>

          <h3 className="mt-12 text-2xl font-medium">
            Recommended areas for Installation
          </h3>
          <p className="mt-6 leading-7">
            Displayed are our recommended areas available within a car that can
            be used to hide Katsana Advanced GPS Tracker. For additional
            security, you may install in other areas your desire.
          </p>

          <h4 className="mt-6 text-xl font-medium text-red-500">CAUTION</h4>
          <div className="flex flex-col mt-4 md:flex-row">
            <StaticImage
              alt="katsana"
              src="../../../static/images/graphics/placement-diagram.png"
              objectFit="contain"
              className="md:mr-8"
            />
            <ul className="mt-6 ml-6 leading-7 list-disc md:w-1/2 md:mt-0">
              <li>Do not place the unit under metal surfaces.</li>
              <li>Do not install next to radio, speakers or alarm systems.</li>
              <li>It must be rigidly mounted on the surface.</li>
              <li>Do not place the unit next to heat or moving parts.</li>
              <li>
                It is recommended to place the unit near areas open to the sky
                (dashboard, rear windscreen, C-Pillar areas, doors, etc)
              </li>
              <li>
                Device can be placed in other areas of the vehicle (internally
                or externally in car, truck, motorcycle) wherever there is
                visibility to the satellites.
              </li>
            </ul>
          </div>

          <h4 className="mt-6 text-xl font-medium ">For added Protection</h4>
          <ul className="mt-4 ml-6 leading-7 list-disc">
            <li>
              Thief would normally inspect the dashboard area for GPS tracking
              system. Thus, install the unit in random areas to minimize the
              chances of Katsana tracker being discovered.
            </li>
            <li>
              Do not let people know the existence and whereabout of your GPS
              tracking unit.
            </li>
            <li>
              Install connecting wires as discreetly as possible, making it less
              obvious and harder to notice
            </li>
          </ul>
        </div>
      </div>
      <Cta />
    </Layout>
  );
};

export default Guidelines;
